@import '../../../styles/customMediaQueries.css';

.root {
  composes: marketplaceModalFormRootStyles from global;

  justify-content: flex-start;

  @media (--viewportMedium) {
    justify-content: space-between;
  }
}

.bottomWrapper {
  & > button {
    margin-top: 32px;
  }
}
.formFld {
  margin-bottom: 24px;
  & input {
    min-height: 50px;
  }
}

/* Adjust the bottomWrapper's margin to match modals without the helper text */
.bottomWrapperText {
  margin: 10px 0;
}

.recoveryLink {
  composes: marketplaceModalHelperLink from global;
}

.recoveryLinkInfo {
  composes: marketplaceModalHelperText from global;
}
