@import '../../styles/customMediaQueries.css';

.root {
  flex-grow: 1;
}

.contentContainer {
  composes: marketplaceModalRootStyles from global;
  min-height: calc(100vh - 60px);
  height: auto;

  @media (--viewportMedium) {
    height: unset;
    min-height: unset;
  }
}

.content {
  max-width: 400px;
  padding: 24px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}

/* ================ Tabs & Form ================ */

.tabs {
}

.tab {
  composes: marketplaceModalTitleStyles from global;
  font-weight: var(--fontWeightSemiBold);
  margin-top: 0;
  margin-bottom: 0;
  font-size: 20px;
  line-height: 100%;

  @media (--viewportMedium) {
    margin-top: 0;
  }
}

.form {
  margin-top: 24px;

  @media (--viewportMedium) {
    margin-top: 30px;
    padding-top: 2px;
  }
}

.loginForm {
  margin: 24px 0;
  float: left;
  width: 100%;

  @media (--viewportMedium) {
    margin: 32px 0;
  }
}

.signupForm {
  margin: 24px 0;
  float: left;
  width: 100%;

  @media (--viewportMedium) {
    margin: 32px 0;
  }
}

.bottomWrapper {
  composes: marketplaceModalBottomWrapper from global;

  /* Align the helper links to the left since there isn't a Primary Button anymore */
  text-align: left;
}

/* Terms of Service modal*/

.privacyWrapper,
.termsWrapper {
  width: 100%;
  padding-top: 40px;

  @media (--viewportMedium) {
    width: 604px;
    padding-top: 11px;
  }
}

/* ================ Hide Top bar in screens smaller than 768px  ================ */

.hideOnMobile {
  display: none;

  @media (--viewportMedium) {
    display: block;
  }
}

/* ================ Close icon ================ */

.verifyClose {
  composes: marketplaceModalCloseStyles from global;

  /* This is not a real modal, z-index should not be modal's z-index */
  z-index: 1;
}

.closeText {
  composes: marketplaceModalCloseText from global;
}

.closeIcon {
  composes: marketplaceModalCloseIcon from global;
}

/* ================ Typography ================ */

/* Title of the modal */
.modalTitle {
  composes: marketplaceModalTitleStyles from global;
}

/* Paragraph for the Modal */
.modalMessage {
  composes: marketplaceModalParagraphStyles from global;
}

/* Make the email pop */
.email {
  font-weight: var(--fontWeightHighlightEmail);

  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;
}

/* Helper links */
.modalHelperLink {
  composes: a from global;
  composes: marketplaceModalHelperLink from global;
}

/* Helper texts for the links, not the actual links */
.modalHelperText {
  composes: marketplaceModalHelperText from global;
}

.modalIcon {
  composes: marketplaceModalIconStyles from global;

  /* Align the icon and rest of the content in the modal. Sign & Login doesn't use an icon, maybe we should add one? */
  margin-top: 47px;

  @media (--viewportMedium) {
    margin-top: 0;
  }
}

/* ================ Errors ================ */

.error {
  composes: marketplaceModalPasswordMargins from global;
  color: var(--colorFail);
  text-align: center;
}

/* ================ Social logins & SSO ================ */

.signupWithIdpTitle {
  composes: marketplaceModalTitleStyles from global;
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 16px;
  color: var(--colorBlack);

  @media (--viewportMedium) {
    padding: 0;
    margin-top: 6px;
  }
}

.confirmInfoText {
}

.buttonIcon {
  position: absolute;
  left: 0;
  margin-left: 20px;
}

.socialButtonWrapper {
  margin-bottom: 6px;
  @media (--viewportMedium) {
    margin-top: 8px;
  }
}

.socialButtonsOr {
  width: 100%;
  height: 32px;
  margin: 28px 0 20px 0;
  text-align: center;
  position: relative;
  background-color: hsl(0deg 0% 98.04%);

  &:after {
    content: '';
    width: 100%;
    border-bottom: solid 1px #d2d2d2;
    position: absolute;
    left: 0;
    top: 50%;
    z-index: 1;
  }
}

@media (--viewportMedium) {
  .socialButtonsOr {
    height: 34px;
    margin: 15px 0;
  }
}

.socialButtonsOrText {
  background-color: hsl(0deg 0% 98.04%);
  width: auto;
  display: inline-block;
  z-index: 3;
  padding: 0 20px 0 20px;
  position: relative;
  margin: 0;
}

.authContent {
  max-width: var(--contentMaxWidth);
  width: 100%;
  margin: 24px auto;
  @media (--viewportMedium) {
    margin: 50px auto;
  }
}

.authHead {
  text-align: center;
  & > a {
    display: flex;
    align-items: center;
    justify-content: center;
    & > div {
      width: auto;
      max-width: initial;
      height: auto;
    }
    & img {
      max-width: initial;
      width: 100px;
      height: auto;
      max-height: max-content;
    }
  }
  & > h1 {
    font-size: 24px;
    color: var(--colorBlack);
    line-height: 110%;
    margin: 20px 0 10px;
    font-weight: var(--fontWeightSemiBold);
    float: left;
    width: 100%;
    @media (--viewportLarge) {
      font-size: 30px;
    }
  }
  & > p {
    font-size: 16px;
    font-weight: var(--fontWeightRegular);
    line-height: 140%;
    color: var(--colorGrey800);
    float: left;
    width: 100%;
    margin: 0;
  }
}
