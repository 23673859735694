@import '../../../styles/customMediaQueries.css';

.root {
  composes: marketplaceModalFormRootStyles from global;

  justify-content: flex-start;

  @media (--viewportMedium) {
    justify-content: space-between;
  }
}

.formFld {
  margin-bottom: 24px;
  & input {
    min-height: 50px;
  }
}

.bottomWrapper {
  & > button {
    margin-top: 32px;
  }
}
